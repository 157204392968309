<template>
    <div class="data_view_main">
        <div class="global_search">
            <div class="search_type">
                <div class="search_item" :class="headSearchType == 'ad'?'active_item':''" @click="changeHeadSearchType('ad')">查广告创意</div>
                <div class="search_item" :class="headSearchType == 'wd'?'active_item':''" @click="changeHeadSearchType('wd')">查关键词</div>
                <div class="search_item" :class="headSearchType == 'cp'?'active_item':''" @click="changeHeadSearchType('cp')">查推广公司</div>
            </div>
            <div class="input">
                <input @input="getSuggestion" @blur="hiddenHistory" type="text" v-model="searchKey" @keypress.enter="handleHeadSearch" :placeholder="headSearchType=='ad'?'请输入推广关键词':headSearchType=='wd'?'请输入关键词':'请输入网站名称/网址、公司名、行业名、推广关键词等'">
                <div class="search_btn" @click="handleHeadSearch">查一查</div>
                <div class="empty_input" v-if="showEmptyInput">
                    <img src="@/assets/img/companyDetails/icon_exclamation.png" alt="">
                    <span>你还未输入关键词</span>
                </div>
                <div class="search_history" v-show="showInputSuggest && searchSuggestList.length">
                    <div class="search_item" v-for="(item,i) in searchSuggestList" :key="i" @click="selectHistory(item.company_name)">{{item.company_name}}</div>
                </div>
            </div>
            <img class="left_img" src="../../../assets/img/dataView/bg_left.png"/>
            <img class="right_img" src="../../../assets/img/dataView/bg_right.png"/>
        </div>
        <div class="data_status">
            <div class="title">
                <span class="title_text">全网广告大数据监测状态</span>
                <span class="title_time">更新时间：{{monitorData.update_time}}</span>
            </div>
            <div class="card_group">
                <div class="card_item line4">
                    <img src="../../../assets/img/dataView/line2_4.png"/>
                    <div>
                        <div class="text">创意素材</div>
                        <countTo class="count" :startVal='0' :endVal='parseInt(monitorData.record_cnt) || 0' :duration='1500' autoplay></countTo>
                    </div>
                </div>
                <div class="card_item line3">
                    <img src="../../../assets/img/dataView/line2_3.png"/>
                    <div>
                        <div class="text">关键词</div>
                        <countTo class="count" :startVal='0' :endVal='parseInt(monitorData.word_cnt) || 0' :duration='1500' autoplay></countTo>
                    </div>
                </div>
                <div class="card_item line2">
                    <img src="../../../assets/img/dataView/line2_2.png"/>
                    <div>
                        <div class="text">推广域名</div>
                        <countTo class="count" :startVal='0' :endVal='parseInt(monitorData.domain_cnt) || 0' :duration='1500' autoplay></countTo>
                    </div>
                </div>
                <div class="card_item line1">
                    <img src="../../../assets/img/dataView/line2_1.png"/>
                    <div>
                        <div class="text">推广公司</div>
                        <countTo class="count" :startVal='0' :endVal='parseInt(monitorData.company_cnt) || 0' :duration='1500' autoplay></countTo>
                    </div>
                </div>
            </div>
        </div>
        <div class="main_group">
            <div class="main_group_item margin_bottom">
                <div class="item_title">
                   <span class="title left">
                        我的推广竞品
                       <span class="text margin_left">当前公司：
                           <img v-if="searchCompany.default" src="@/assets/img/search/icon_lock.png" alt="" style="width: 12px;height: 12px">
                           <div class="company_limit">{{searchCompany.company_name}}</div>
                       </span>
                       <span class="margin_left canClick" @click="changeCompany">
                           <span class="text" style="color: #1f81f8;">切换</span>
                            <img src="../../../assets/img/search/icon_change.png"/>
                       </span>
                   </span>
                    <el-button
                        class="right"
                        type="text"
                        @click="handleFire(3109),
                                $router.push({
                                    path: '/box/findCompetitive',
                                    query: {company:JSON.stringify(searchCompany)}
                                })">查看更多
                        <i class="el-icon-arrow-right el-icon--right"></i>
                    </el-button>
                </div>
                <div  v-loading="tableLoading1" style="height: 307px;">
                    <template v-if="$store.state.userInfo.vip_type != 5">
                        <div class="item_list" v-if="competitiveList.length > 0">
                            <div class="item_list_item" v-for="(item, i) in competitiveList" :key="i">
                                <div class="circle"
                                     :style="i === 0?{'background-color': '#1f81f8', 'color': '#ffffff'}:
                                    i === 1?{'background-color': '#56cec7', 'color': '#ffffff'}:
                                    i === 2?{'background-color': '#f5a623', 'color': '#ffffff'}:''
                            "
                                >{{i < 9? '0'+(i+1):1+i}}</div>
                                <div class="right">
                                    <div class="company_name" @click="$C.getCompanyDetails(item.company_name_digest), handleFire(3108)">{{item.compete_company_name}}</div>
                                    <div class="title_time" style="margin-top: 7px">相同关键词数：{{item.compete_word_cnt}}</div>
                                </div>
                            </div>
                        </div>
                        <NoData v-if="competitiveList.length <= 0 && !tableLoading1"></NoData>
                    </template>
                    <NoData v-else>
                        <img slot="img" style="width: 187px; margin-bottom: 13px" src="../../../assets/img/icon/no_jurisdiction.png"/>
                        <div slot="text" style="text-align: center">
                            <div style="font-size: 16px; color: #333333; margin-bottom: 16px">暂无权限</div>
                            <div style="color: #999999; font-size: 14px;">标准版会员可查看竞品公司，请联系在线客服开通</div>
                        </div>
                    </NoData>
                </div>
            </div>
            <div class="main_group_item margin_bottom">
                <div class="item_title">
                    <span class="title left">推广监测</span>
                    <el-button class="right" type="text" @click="$router.push('/box/dynamic'), handleFire(3110)">
                        查看更多
                        <i class="el-icon-arrow-right el-icon--right"></i>
                    </el-button>
                </div>
                <div class="monitor_count">
                    <div class="all_data">
                        <div class="data_item">
                            <span class="text">监测竞品</span>
                            <div class="line_box">
                                <div style="width: 55px; display: inline-block">
                                    <el-progress
                                         v-if="allMonitorData.monitor_company_total"
                                        :percentage="handlePercentage(allMonitorData.monitor_company_cnt, allMonitorData.monitor_company_total)"
                                        :show-text="false">
                                    </el-progress>
                                </div>
                                <span><span style="color: #1f81f8;">{{allMonitorData.monitor_company_cnt}}</span>/{{allMonitorData.monitor_company_total}}</span>
                            </div>
                        </div>
                        <div class="data_item">
                            <span class="text">监测关键词</span>
                            <div class="line_box">
                                <div style="width: 55px; display: inline-block">
                                    <el-progress
                                        v-if="allMonitorData.monitor_word_total"
                                        :percentage="handlePercentage(allMonitorData.monitor_word_cnt, allMonitorData.monitor_word_total)"
                                        :show-text="false">
                                    </el-progress>
                                </div>
                                <span><span style="color: #1f81f8;">{{allMonitorData.monitor_word_cnt}}</span>/{{allMonitorData.monitor_word_total}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="xian"></div>
                    <div class="dynamic_state">
                        <span class="title">竞品动态</span>
                        <span class="title_time">昨日 <span style="color: #ef3819;">{{allMonitorData.yesterday_monitor_company_result_cnt}}</span></span>
                    </div>
                    <div class="dynamic_state">
                        <span class="title">关键词动态</span>
                        <span class="title_time">昨日 <span style="color: #ef3819;">{{allMonitorData.yesterday_monitor_word_result_cnt}}</span></span>
                    </div>
                </div>
                <div class="line_chart" id="line_chart"></div>
                <div v-if="noMonitorData" class="toSearch">
                    <el-button
                        class="right"
                        @click="handleFire(3111), $router.push('/box/findCompetitive')"
                        type="text">
                        去添加监测对象<i class="el-icon-arrow-right el-icon--right"></i>
                    </el-button>
                </div>
            </div>
            <div class="main_group_item">
                <div class="item_title">
                    <span class="title left">全网搜索推广</span>
                    <span class="right title_time">更新时间：{{platformData.update_time}}</span>
                </div>
                <div class="platform_group">
                    <div class="platform_item" v-for="(item, i) in platformData.buckets" :key="i">
                        <div class="circle"
                             :style="i === 0?{'background-color': '#1f81f8', 'color': '#ffffff'}:
                                    i === 1?{'background-color': '#56cec7', 'color': '#ffffff'}:
                                    i === 2?{'background-color': '#f5a623', 'color': '#ffffff'}:''
                            "
                        >{{i < 9? '0'+(i+1):1+i}}</div>
                        <span class="name">{{$C.platformTurn(item.key)}}</span>
                        <div class="percentage_box">
                            <div class="title_time">
                                <span>推广数量 </span>
                                <span class="margin_left">{{item.doc_count}}</span>
                            </div>
                            <el-progress
                                style="margin-top: 5px"
                                v-if="item.doc_count"
                                :color="
                                    i === 0? 'rgba(239, 56, 25, 0.7)':
                                    i === 1? 'rgba(239, 56, 25, 0.7)':
                                    i === 2? 'rgba(245, 166, 35, 0.7)':
                                    i === 3? 'rgba(245, 166, 35, 0.7)':
                                    i === 4? 'rgba(31, 129, 248, 0.7)':
                                    i === 5? 'rgba(31, 129, 248, 0.7)':
                                    i === 6? 'rgba(86, 206, 199, 0.7)':''
                                 "
                                :percentage="toInit(item.doc_count/platformData.total*100)"
                                :show-text="false">
                            </el-progress>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main_group_item">
                <div class="item_title">
                    <span class="title left">动态关键词</span>
                    <span class="right title_time">更新时间：{{adWorldData.update_time}}</span>
                </div>
                <div class="world_could" id="world_could"></div>
            </div>
        </div>

        <!--弹窗部分-->
        <el-dialog
            custom-class="my_dialog"
            :before-close="beforeClose"
            :visible.sync="showDialog">
            <template #title>
                <span class="my_dialog_title">切换公司</span>
            </template>
            <div class="search_line">
                <el-input
                    placeholder="请输入公司名称"
                    v-model="changedCompanyName"
                    @input="handleSearch()"
                    class="input-with-select">
                    <div slot="prepend">
                        <el-select v-model="searchType" placeholder="请选择" @change="changeSearchType">
                            <el-option label="全部公司" :value="1"></el-option>
                            <el-option label="我的收藏" :value="2"></el-option>
                        </el-select>
                    </div>
                    <div slot="append">
                        <el-button icon="el-icon-search" @click="handleSearch()"></el-button>
                    </div>
                </el-input>
            </div>
            <div class="collection_head" v-if="collectionList.length && !changedCompanyName">
                <img src="@/assets/img/search/icon_collect.png" alt="" width="14" height="14">
                <span>我的收藏</span>
            </div>
            <div class="collection_list" v-if="collectionList.length" v-loading="tableLoading2">
                <div class="list_item" v-for="(item,i) in collectionList" :key="i">
                    <span class="list_left">
                        <span class="company_name" @click="selectedCompany(item)">{{item.company_name}}</span>
                        <img @click="cancelCollect(item.company_name_digest)" v-if="item.collect" src="@/assets/img/search/collected.png" alt="" width="14" height="14">
                        <img @click="addCollect(item.company_name_digest)" v-else src="@/assets/img/search/uncollect.png" alt="" width="14" height="14">
                    </span>
                    <span class="list_right">
                        <el-checkbox v-model="item.default" @change="addDefault(item)" :disabled="item.default">设为默认</el-checkbox>
                    </span>
                </div>
            </div>
            <no-data v-else></no-data>
            <template #footer>
               <span class="dialog-footer">
                  <el-button @click="beforeClose">取 消</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
  import countTo from 'vue-count-to';
  import * as apiDataView from "@/api/dataView"
  import * as api from "@/api/search"
  import * as echarts from 'echarts';
  import * as search from "@/api/search"
  import 'echarts-wordcloud';
  import {optionLine} from './line_chart_option'
  import PinganAna from 'pingansec-vue-ana'

  export default {
    name: "index",
    components: { countTo },
    data() {
      return {
        headSearchType: 'ad',
        searchKey: '',
        showEmptyInput: false,
        userInfo: {},
        show_ma : false,
        monitorData: {},
        searchCompany: {},
        competitiveList: [],
        tableLoading1: true, //我的竞品loading
        searchType: 1,
        showDialog: false,
        changedCompanyName: '',  //切换公司搜索关键词
        collectionList: [], //我的收藏列表
        tableLoading: false,
        tableLoading2: false,
        allMonitorData: {}, //推广监测看板数据
        noMonitorData: false,
        platformData: {}, //全网搜索推广数据
        adWorldData: {}, //动态关键词数据
        showInputSuggest: false,
        searchSuggestList: [],
      }
    },
    mounted() {
      PinganAna.fire(3093)
      this.getUserInfo()
      this.getAllMonitorData();
      this.getPlatformData();
      this.getAdWorldData();
      this.getDefaultCompany();
      this.getMonitorData();
    },
    methods: {
      hiddenHistory() {
        setTimeout(() => {
          this.showInputSuggest = false
        },500)
      },
      selectHistory(word) {
        this.searchKey = word;
        this.showInputSuggest = false
        this.handleHeadSearch();
      },
      getSuggestion() {
        if(this.searchKey && this.headSearchType == 'cp') {
          this.showEmptyInput = false;
          this.showInputSuggest = true ;
          let params = {
            keyword: this.searchKey,
            type: 1
          };
          search.get_ad_compete_list_search(params).then(res => {
            if(res.data.result_code == 0) {
              this.searchSuggestList = res.data.data;
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        }
      },
      handleHeadSearch() {
        if(!this.searchKey) {
          this.showEmptyInput = true;
          setTimeout(() => {
            this.showEmptyInput = false;
          }, 3000)
          return
        }
        let path = '/box/search'
        let query = { word: this.searchKey}
        switch (this.headSearchType) {
          case "ad":
            path += '/findAdvertising'
            break;
          case "wd":
            path += '/findWord'
            break;
          case "cp":
            path += '/findCompany'
            break
        }
        this.$router.push({
          path,
          query,
        })
      },
      changeHeadSearchType(type) {
        if(this.headSearchType != type) {
          this.headSearchType = type
          this.showInputSuggest = false
        }
      },
      handleFire(point) {
        PinganAna.fire(point)
      },
      handlePercentage(a, b) {
        let count = parseInt(a / b * 100);
        return count;
      },
      getDay(day){
        var today = new Date();
        var targetday_milliseconds=today.getTime() + 1000*60*60*24*day;
        today.setTime(targetday_milliseconds);
        var tMonth = today.getMonth();
        var tDate = today.getDate();
        tMonth = this.doHandleMonth(tMonth + 1);
        tDate = this.doHandleMonth(tDate);
        return tMonth+"-"+tDate;
      },
      doHandleMonth(month){
        var m = month;
        if(month.toString().length == 1){
          m = "0" + month;
        }
        return m;
      },
      getUserInfo() {
        this.$G.getUserInfo().then((res) => {
          this.userInfo = res;
        });
      },
      getAdWorldData() {
        apiDataView.ad_words_show().then(res =>{
          if(res.data.result_code == 0) {
            this.adWorldData = res.data.data;
            this.$nextTick(() =>{
              this.drawWorldCould()
            })
          }
        })
      },
      drawWorldCould() {
        let myChart = echarts.init(document.getElementById('world_could'))
        let slice_part_count_arr = [1,5,10 ,20,64]
        let font_size_arr = [50,35,26,18,10]
        let sum = function(arr) {
          var len = arr.length;
          if(len == 0){
            return 0;
          } else if (len == 1){
            return arr[0];
          } else {
            return arr[0] + sum(arr.slice(1));
          }
        }
        let data_count = sum(slice_part_count_arr);
        let data = this.adWorldData.worldCloud.slice(0, data_count);
        let index = 0
        for(var i = 0; i < slice_part_count_arr.length; i++) {
          for(var j = 0; j < slice_part_count_arr[i]; j++)
          {
            data[index]['size'] = font_size_arr[i];
            index++
          }
        }
        data = data.map(e => {
          return {
            name : e.name,
            size : parseInt(e.value),
            value : e.size
          }
        })
        var that = this;
        myChart.setOption({
          // color:['#f5a622', '#9767f9', '#397cff', '#f67e89', '#7bee94', '#e56183'],
          tooltip: {
            className: 'worldcould-tooltip',
            formatter: function (params) {
              let dian = params.marker;
              var string = dian  + '<span class="tooltip_name">'+params.data.name+'：</span>' +  '<span class="tooltip_count"><span class="tooltip_img"></span>'+params.data.size+'</span>';
              return string
            },
          },
          series: [{
            type: 'wordCloud',
            shape: 'circle',
            data: data,
            width: '90%',
            height: '90%',
            gridSize: 10,
            sizeRange: [10, 40],
            // rotationRange: [-90, 90],
            rotationRange: [0,0],
            // rotationStep: 30,
            textStyle: {
              fontFamily: 'sans-serif',
              // fontWeight: 'bold',
              color: function () {
                // Random color
                return 'rgb(' + [
                  Math.round(Math.floor(Math.random()*(230-130+1)+130)),
                  Math.round(Math.floor(Math.random()*(230-130+1)+130)),
                  Math.round(Math.floor(Math.random()*(230-130+1)+130))
                ].join(',') + ')';
              }
            },
          }]
        });
        window.addEventListener("resize", () => { myChart.resize(); });
        myChart.on('click', function (params) {
          PinganAna.fire(3112)
          that.$router.push({
            path: '/box/search/findAdvertising',
            query: {
              word: params.name
            }
          })
        });
      },
      getPlatformData() {
        apiDataView.ad_platform_distribution().then(res =>{
          if(res.data.result_code == 0) {
            this.platformData = res.data.data;
          }
        })
      },
      drawLine(){
        let myChart = echarts.init(document.getElementById('line_chart'))
        let that = this;
        if(this.allMonitorData.chart.x.length > 0 && !this.noMonitorData) {
          let data = this.allMonitorData.chart;
          myChart.setOption(optionLine);
          myChart.setOption({
            xAxis: {
              data: data.x,
            },
            series: [
              {data: data.y1},
              {data: data.y2},
            ]
          });
        } else {
          let count = 30;
          if(this.noMonitorData) {
            count = 60;
          }
          myChart.setOption(optionLine);
          myChart.setOption({
            grid: {
              bottom: count,
            },
            xAxis: {
              data: [that.getDay(-8),that.getDay(-7),that.getDay(-6),that.getDay(-5),that.getDay(-4),that.getDay(-3),that.getDay(-2),that.getDay(-1),that.getDay(0),],
            },
            yAxis: {
              type: 'category',
              data: ['200','400','600','800'],
            },
          });
        }

        window.addEventListener("resize", () => { myChart.resize(); });
        // myChart.setOption(optionLine)
      },
      toInit(str) {
        return parseInt(str) < 1 ? 1 : parseInt(str)
      },
      getAllMonitorData() {
        apiDataView.ad_monitor_change_line().then(res => {
          if(res.data.result_code == 0) {
            this.allMonitorData = res.data.data.re_data;
            if(this.allMonitorData.chart.y1.length==0 && this.allMonitorData.chart.y2.length==0) {
              PinganAna.fire(3113)
            }
            if(this.allMonitorData.monitor_company_cnt == 0 && this.allMonitorData.monitor_word_cnt == 0){
              this.noMonitorData = true;
            }
            this.$nextTick(() =>{
              this.drawLine();
            })
          }
        })
      },
      getMonitorData() {
        apiDataView.ad_statistics().then(res => {
          if(res.data.result_code == 0) {
            this.monitorData = res.data.data;
          }
        })
      },
      beforeClose() {
        this.showDialog = false;
        this.changedCompanyName = '';
        this.init();
        // this.getDefaultCompany();
      },
      changeCompany() {
        PinganAna.fire(3106)
        this.showDialog = true;
        this.getCollectedData();
      },
      changeSearchType() {
        this.changedCompanyName = '';
      },
      addDefault(item) {
        let params = {
          key: 'search_1',
          val: item.company_name_digest
        };
        api.set_val_by_key(params).then(res => {
          if(res.data.result_code == 0) {
            this.$message({
              type: 'success',
              message: '设置默认成功！'
            });
            this.getCollectedData()
            this.getDefaultCompany();
          } else {
            this.$message({
              type: 'error',
              message: '设置默认失败！'
            })
          }
        })
      },
      addCollect(digest) {
        let params = {
          digest
        };
        api.add_collect(params).then(res =>{
          if(res.data.result_code == 0) {
            this.$message({
              type: 'success',
              message: '收藏成功，还可收藏' + res.data.data.last + '家公司'
            })
            this.getCollectedData()
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      cancelCollect(digest) {
        let params = {
          digest
        };
        api.cancel_collect(params).then(res => {
          if(res.data.result_code == 0) {
            this.$message({
              type: 'success',
              message: '取消收藏成功，还可收藏' + res.data.data.last + '家公司'
            })
            this.getCollectedData()
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      selectedCompany(item) {
        PinganAna.fire(3107)
        this.searchCompany = item;
        this.beforeClose();
      },
      handleSearch() {
        let params = {
          keyword: this.changedCompanyName,
          type: this.searchType
        };
        this.tableLoading2 = true;
        api.get_ad_compete_list_search(params).then(res => {
          if(res.data.result_code == 0) {
            this.collectionList = res.data.data;
            this.tableLoading2 = false;
          }
        })
      },
      getCollectedData() {
        let params = {
          keyword: this.changedCompanyName,
          type: this.searchType
        };
        this.tableLoading2 = true;
        api.get_ad_compete_list_search(params).then(res => {
          if(res.data.result_code == 0) {
            if(res.data.data) {
              this.collectionList = res.data.data;
            }
            this.tableLoading2 = false;
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            });
            this.tableLoading2 = false;
          }
        })
      },
      getDefaultCompany() {
        let params = {
          key: 'search_1',
        };
        this.tableLoading1 = true;
        api.get_val_by_key(params).then(res => {
          if(res.data.result_code == 0) {
            this.searchCompany = res.data.data;
            this.searchCompany.default = true;
            if(this.$store.state.userInfo.vip_type != 5) {
              this.init();
            } else {
              this.tableLoading1 = false
            }
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      init() {
        let params = {
          digest: this.searchCompany.company_name_digest,
          sort_key: '',
          sort_type: '',
          page: 1,
          limit: 10,
          query_page: 'dataView',
        };
        api.get_ad_compete_list(params).then(res => {
          if(res.data.result_code == 0) {
            if(res.data.data) {
              this.competitiveList = res.data.data.rows;
            }
            this.tableLoading1 = false
          }
        })
      },
    }
  }
</script>

<style scoped lang="scss">
    .data_view_main {
        width: 100%;
        .title_text {
            color: #333333;
            font-size: 16px;
        }
        .title_time {
            font-size: 12px;
            color: #999999;
        }
        .margin_bottom {
            margin-bottom: 10px;
        }
        .margin_left {
            margin-left: 10px;
        }
        .canClick {
            cursor: pointer;
        }
        .global_search {
            padding: 25px;
            background-color: #1f81f8;
            border-radius: 5px;
            position: relative;
            height: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .search_type {
                display: flex;
                width: 653px;
                margin-bottom: 12px;
                margin-left: 18px;
                z-index: 5;
                .search_item {
                    padding: 7px 10px;
                    font-size: 14px;
                    color: #ffffff;
                    margin-right: 12px;
                    cursor: pointer;
                }
                .active_item {
                    color: #1f81f8;
                    background-color: #fff;
                    border-radius: 3px;
                    position: relative;
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: -6px;
                        left: calc(50% - 8px);
                        display: inline-block;
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        border-top: 6px solid #fff;
                    }
                }
            }
            .input {
                display: inline-flex;
                z-index: 5;
                position: relative;
                input {
                    height: 38px;
                    width: 550px;
                    background-color: #ffffff;
                    border: none;
                    color: #333;
                    padding-left: 10px;
                    font-size: 14px;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    position: relative;
                    &::-webkit-input-placeholder {
                        color: #cccccc;
                        line-height: 20px;
                    }
                }
                .search_btn {
                    display: inline-block;
                    color: #1f81f8;
                    width: 100px;
                    height: 40px;
                    text-align: center;
                    line-height: 40px;
                    background-color: #E8F2FE;
                    font-size: 16px;
                    cursor: pointer;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    &:hover {
                        background-color: #C6DEF9;
                    }
                }
                .empty_input {
                    width: 186px;
                    height: 44px;
                    background-color: #fffaf7;
                    box-shadow: 0 4px 10px 0 rgba(204, 204, 204, 0.3);
                    border-radius: 6px;
                    border: solid 1px #ffd1b5;
                    display: flex;
                    align-items: center;
                    position: absolute;
                    bottom: -46px;
                    left: 0;
                    img {
                        height: 14px;
                        width: 14px;
                        margin-left: 16px;
                        margin-right: 10px;
                    }
                    span {
                        font-size: 14px;
                        color: #333333;
                    }
                }
            }
            .search_history {
                width: 561px;
                background-color: #ffffff;
                box-shadow: 0px 4px 10px 0px rgba(204, 204, 204, 0.3);
                border-radius: 6px;
                border: solid 1px #ebeff2;
                position: absolute;
                left: 0;
                top: 40px;
                max-height: 174px;
                overflow-y: auto;
                z-index: 10;
                &::-webkit-scrollbar {
                    display: none;
                }
                .search_item {
                    height: 32px;
                    font-size: 14px;
                    color: #333333;
                    padding-left: 15px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    &:hover {
                        background-color: #e8f2fe;
                        color: #1f81f8;
                    }
                }
            }
            .left_img {
                height: 95px;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 2;
            }
            .right_img {
                height: 91px;
                position: absolute;
                right: 16px;
                bottom: 0;
                z-index: 2;
            }
        }
        .data_status {
            width: calc(100% - 40px);
            height: 95px;
            background-color: #ffffff;
            border-radius: 5px;
            border: solid 1px #ebeff2;
            padding: 18px 20px 18px 20px;
            margin-top: 10px;
            margin-bottom: 10px;
            .title {
                display: flex;
                justify-content: space-between;
            }
            .card_group {
                margin-top: 19px;
                .card_item {
                    width: calc(25% - 12.75px);
                    min-width: 210px;
                    height: 61px;
                    border-radius: 5px;
                    display: inline-flex;
                    align-items: center;
                    margin-right: 17px;
                    img {
                        width: 35px;
                        height: 35px;
                        margin-left: 17px;
                        margin-right: 11px;
                    }
                    .text {
                        font-size: 14px;
                        margin-bottom: 8px;
                    }
                    .count {
                        font-size: 16px;
                        font-weight: bold;
                    }
                }
                .line1 {
                    background-color: rgba(0, 192, 247, 0.2);
                    color: rgba(0, 192, 247, 1);
                    margin-right: 0;
                    /*&:hover {*/
                    /*    background-color: rgba(0, 192, 247, 1);*/
                    /*    color: #ffffff;*/
                    /*}*/
                }
                .line2 {
                    background-color: rgba(150, 148, 255, 0.2);
                    color: rgba(150, 148, 255, 1);
                    /*&:hover {*/
                    /*    background-color: rgba(150, 148, 255, 1);*/
                    /*    color: #ffffff;*/
                    /*}*/
                }
                .line3 {
                    background-color: rgba(93, 218, 180, 0.2);
                    color: rgba(93, 218, 180, 1);
                    /*&:hover {*/
                    /*    background-color: rgba(93, 218, 180, 1);*/
                    /*    color: #ffffff;*/
                    /*}*/
                }
                .line4 {
                    background-color: rgba(255, 121, 118, 0.2);
                    color: rgba(255, 121, 118, 1);
                    /*&:hover {*/
                    /*    background-color:  rgba(255, 121, 118, 1);*/
                    /*    color: #ffffff;*/
                    /*}*/
                }
            }
        }
        .main_group {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .main_group_item {
                width: calc(50% - 7.5px);
                height: 356px;
                background-color: #ffffff;
                border-radius: 5px;
                border: solid 1px #ebeff2;
                overflow: hidden;
                position: relative;
                .circle {
                    width: 22px;
                    height: 18px;
                    background-color: #f0f5f9;
                    color: #999999;
                    font-size: 10px;
                    text-align: center;
                    line-height: 18px;
                    border-radius: 10px;
                    margin-left: 10px;
                    margin-right: 7px;
                }
                .item_title {
                    width: 100%;
                    height: 51px;
                    background-color: #ffffff;
                    border-bottom: 1px solid #ebeff2;
                    display: flex;
                    align-items: center;
                    color: #333333;
                    font-size: 16px;
                    position: relative;
                    .company_limit {
                        display: inline-block;
                        margin-left: 5px;
                        max-width: 145px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .title {
                        margin-left: 19px;
                    }
                    .text {
                        font-size: 12px;
                        color: #999999;
                    }
                    img {
                        width: 12px;
                        height: 9px;
                        margin-left: 4px;
                    }
                    .right {
                        position: absolute;
                        right: 18px;
                    }
                }
                .item_list {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    height: 307px;
                    .item_list_item {
                        width: 50%;
                        height: 34px;
                        border-bottom: 1px solid #ebeff2;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: flex;
                        align-items: center;
                        color: #333333;
                        font-size: 14px;
                        padding-top: 13px;
                        padding-bottom: 13px;
                        .right {
                            cursor: pointer;
                            &:hover {
                                color: #1f81f8;
                            }
                        }
                    }
                }
                .monitor_count {
                    width: 100%;
                    height: 90px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #ebeff2;
                    justify-content: space-around;
                    .all_data {
                        margin-left: 19px;
                        .data_item {
                            margin-top: 7px;
                            .text {
                                font-size: 12px;
                                color: #333333;
                            }
                            .line_box {
                                margin-top: 9px;
                            }
                        }
                    }
                    .xian {
                        width: 1px;
                        height: 50%;
                        background-color: rgba(235, 239, 242, 0.5);
                        margin-left: 37px;
                        margin-right: 30px;
                    }
                    .dynamic_state {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-right: 65px;
                        .title {
                            font-size: 14px;
                            color: #333333;
                            margin-bottom: 16px;
                        }
                    }
                }
                .line_chart {
                    width: 100%;
                    height: 214px;
                }
                .world_could {
                    width: 100%;
                    height: 303px;
                }
                .platform_group {
                    width: 100%;
                    height: 304px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    .platform_item {
                        width: 100%;
                        height: 25px;
                        display: flex;
                        align-items: center;
                        .name {
                            width: 60px;
                            font-size: 14px;
                            color: #333333;
                            margin-left: 7px;
                            margin-right: 10px;
                        }
                        .percentage_box {
                            width: calc(100% - 140px);
                        }
                    }
                }
                .toSearch {
                    text-align: center;
                    position: absolute;
                    z-index: 2;
                    bottom: 0;
                    left: calc(50% - 67px);
                }
            }
        }
    }
</style>

<style lang="scss">
    .worldcould-tooltip {
        font-size: 12px !important;
        color: #333333 !important;
    }
    .data_view_main .main_group .main_group_item .el-button {
        color: #1f81f8;
        i {
            margin: 0;
        }
    }
    .data_view_main .my_dialog {
        width:  435px;
        background-color: #ffffff;
        border-radius: 5px;
        border: solid 1px #ebeff2;
        .el-dialog__header {
            border-bottom: 1px solid #ebeff2;
            color: #333333;
            font-size: 16px;
            padding: 20px;
        }
        .el-dialog__body {
            .search_line {
                .el-input-group__prepend {
                    width: 93px;
                    padding: 0;
                    .el-select {
                        margin: -10px -5px;
                    }
                }
            }
            .collection_head {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #b5c2d2;
                height: 44px;
                border-bottom: 1px solid #ebeff2;
                box-sizing: border-box;
                img {
                    margin-right: 7px;
                }
            }
            .collection_list {
                font-size: 14px;
                color: #333333;
                max-height: 200px;
                overflow-y: auto;
                scrollbar-width: none; /* Firefox */
                &::-webkit-scrollbar {
                    display: none; /* Chrome Safari */
                }
                .list_item {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 20px;
                    .list_left {
                        display: inline-flex;
                        align-items: center;
                        span {
                            cursor: pointer;
                        }
                        .company_name {
                            &:hover {
                                color: #1f81f8;
                            }
                        }
                    }
                    img {
                        margin-left: 8px;
                        cursor: pointer;
                    }
                    .list_right {
                        .el-checkbox__inner {
                            border-radius: 60%;
                        }
                        .el-checkbox__input.is-disabled+span.el-checkbox__label {
                            color: #2a87f8;
                        }
                        .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
                            background-color: #409EFF;
                            border-color: #409EFF;
                        }
                    }
                }
            }
        }
        .el-dialog__footer {
            padding: 12px 15px;
            border-top: 1px solid #ebeff2;
        }
    }
</style>
