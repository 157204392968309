export const optionLine = {
  color: ["#1f81f8", "#f5a623"],
  title: {
    text: '监测动态',
    top: '15',
    left: '37',
    textStyle: {
      color: '#333333',
      fontSize: '12',
      fontWeight: 'normal'
    }
  },
  legend: {
    top: '17',
    itemGap: 68,
    textStyle: {
      color: '#999999',
      fontSize: '12'
    }
  },
  grid: {
    bottom: 30,
    left: '15%',
  },
  tooltip: {
    trigger: 'axis',
  },
  xAxis: {
    type: 'category',
    axisTick: {
      show: false,
    },
  },
  yAxis: {
    // name: '监测动态',
    nameLocation: 'middle',
    nameGap: 45,
    axisTick: {
      show: false,
    },
  },
  series: [
    {
      name: '竞品动态',
      type: 'line',
      symbol: "none",
    },
    {
      name: '关键词动态',
      type: 'line',
      symbol: "none",
    },
  ]
}