import http from '@/utils/http'

// 广告大数据监测状态
export function ad_statistics(data) {
  return http({
    url: '/u-base/ac-ad_statistics',
    method: 'get',
    params: data
  })
}

// 推广监测
export function ad_monitor_change_line(data) {
  return http({
    url: '/u-base/ac-ad_monitor_change_line',
    method: 'get',
    params: data
  })
}

// 全网搜索推广
export function ad_platform_distribution(data) {
  return http({
    url: '/u-base/ac-ad_platform_distribution',
    method: 'get',
    params: data
  })
}

// 动态关键词
export function ad_words_show(data) {
  return http({
    url: '/u-base/ac-ad_words_show',
    method: 'get',
    params: data
  })
}